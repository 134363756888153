import React, { useEffect, useContext } from 'react'

// CLSX
import clsx from 'clsx'

// COMPONENTS
import UpperSidebarItemList from './UpperSidebarItemList/UpperSidebarItemList'

// CONTEXTS
import { SidebarContext } from '../../contexts/SidebarContext'

// IMAGES
import IconToogleSidebarOff from '../../images/icons/toogle_sidebar_off.svg'
import IconToogleSidebarOn from '../../images/icons/toogle_sidebar_on.svg'
import LogoLacakColor from '../../images/logos/lacakio_color.svg'
import LogoOnlyLacakColor from '../../images/logos/lacakio_color_logo_only.svg'

// MATERIAL UI CORES
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'


// STYLES
import useStyles from './sidebarUseStyles'

const Sidebar = () => {
    const classes = useStyles()

    const { isSidebarOpen,
        changeIsSidebarOpen,
        isSidebarToogle,
        changeIsSidebarToogle,
    } = useContext(SidebarContext)
    
    const onMouseLeaveDrawer = () => {
        if(!isSidebarToogle) changeIsSidebarOpen(false)
    }

    useEffect(() => {
        changeIsSidebarOpen(isSidebarToogle)
    }, [isSidebarToogle])

    return (
        <Drawer
            variant='permanent'
            className={clsx(classes['drawer'], {
                [classes['drawerOpen']]: isSidebarOpen,
                [classes['drawerClose']]: !isSidebarOpen,
            })}
            classes={{
                paper: clsx({
                    [classes['drawerOpen']]: isSidebarOpen,
                    [classes['drawerClose']]: !isSidebarOpen,
                }),
            }}
            onMouseEnter={() => changeIsSidebarOpen(true)}
            onMouseLeave={() => onMouseLeaveDrawer()}
        >
            {/* COMPANY LOGO AND TOOGLE ICON */}
            <div className={classes['logoAndToogleContainer']}>
                {/* COMPANY LOGO */}
                {
                    isSidebarOpen ?
                    <img 
                        src={LogoLacakColor}
                        alt=''
                        className={classes['companyLogoOpen']}
                    /> :
                    <img 
                        src={LogoOnlyLacakColor}
                        alt=''
                        className={classes['companyLogoClose']}
                    />
                }

                {/* TOOGLE ICON */}
                {
                    isSidebarOpen &&
                    <IconButton onClick={() => changeIsSidebarToogle(currentValue => !currentValue)}>
                        <img 
                            src={isSidebarToogle ? IconToogleSidebarOn : IconToogleSidebarOff}
                            alt=''
                        />
                    </IconButton>
                }
            </div>

            {/* UPPER SIDEBAR ITEM LIST */}
            <UpperSidebarItemList/>
        </Drawer>
    )
}

export default Sidebar