import React from 'react'
import { Route } from 'react-router-dom'

// COMPONENTS
import Sidebar from '../../components/Sidebar/Sidebar'

// PAGES
import Sim from '../../pages/Sim/Sim'
import Accessories from '../../pages/Accessories/Accessories'

// STYLES
import useStyles from './dashboardUseStyles'

const Dashboard = () => {
    const classes = useStyles()

    const pageList = [
        {
            exact: true,
            path: '/devices/sim',
            component: <Sim/>,
        },
        {
            exact: true,
            path: '/devices/accessories',
            component: <Accessories/>,
        },
    ]

    return (
        <div className={classes['root']}>
            <Sidebar/>

            {
                pageList.map((item, index) => (
                    <Route 
                        key={index}
                        exact={item['exact']}
                        path={item['path']}
                    >
                        {item['component']}
                    </Route>
                ))
            }
        </div>
    )
}

export default Dashboard