// MATERIAL UI CORES
import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 340

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 100,
    },
    logoAndToogleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '30px 10px 50px 40px',
        height: 45,
    },
    companyLogoOpen: {
        height: 30,
        cursor: 'pointer',
    },
    companyLogoClose: {
        height: 30,
    },
}))

export default useStyles