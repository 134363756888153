import React, { createContext, useState } from 'react'

const SidebarContext = createContext()

const SidebarContextProvider = (props) => {
    const [ isSidebarOpen, setIsSidebarOpen ] = useState(true)
    const [ isSidebarToogle, setIsSidebarToogle ] = useState(true)

    const changeIsSidebarOpen = (inputValue) => {
        setIsSidebarOpen(inputValue)
    }

    const changeIsSidebarToogle = (inputValue) => {
        setIsSidebarToogle(inputValue)
    }

    return (
        <SidebarContext.Provider
            value={{
                isSidebarOpen,
                changeIsSidebarOpen,
                isSidebarToogle,
                changeIsSidebarToogle,
            }}
        >
            {props['children']}
        </SidebarContext.Provider>
    )
}

export { SidebarContextProvider, SidebarContext }