import React from 'react'

const Sim = () => {
    return (
        <div>
            Sim
        </div>
    )
}

export default Sim