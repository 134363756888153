// MATERIAL UI CORES
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import color from '../../../utils/color'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
    },
    title: {
        paddingLeft: 40,
        color: color['darkslategray'],
        marginBottom: 10,
    },
    iconAndTextContainer: {
        padding: 0,
        paddingLeft: 55,
        height: 65,
    },
    selectedItem: {
        backgroundColor: color['lavender'],
    },
    childrenText: {
        textDecoration: 'none',
        color: color['slategray'],
    },
    selectedChildrenText: {
        color: color['dodgerblue'],
        fontWeight: 600,
    },
    addition: {
        backgroundColor: color['dodgerblue'],
        width: 4,
        height: '100%',
        borderRadius: '4px 0px 0px 4px',
    },
    iconAndTextClosedContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0,
        width: '100%',
        height: 65,
        alignItems: 'center',
    },
    iconClosedSelected: {
        marginLeft: 'auto',
    }
}))

export default useStyles