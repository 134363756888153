import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

// CONTEXTS
import { SidebarContext } from '../../../contexts/SidebarContext'

// ICONS
import IconSimGray from '../../../images/icons/sidebar_sim_gray.svg'
import IconSimBlue from '../../../images/icons/sidebar_sim_blue.svg'
import IconAccessoriesGray from '../../../images/icons/sidebar_accessories_gray.svg'
import IconAccessoriesBlue from '../../../images/icons/sidebar_accessories_blue.svg'

// MATERIAL UI CORES
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './upperSidebarItemListUseStyles'

const SidebarItemList = () => {
    const classes = useStyles()

    const { isSidebarOpen } = useContext(SidebarContext)

    let { pathname } = useLocation()
    pathname = pathname.split('/')
    pathname.shift()

    const itemList = [
        {
            title: 'Devices',
            children: [
                {
                    iconDefault: IconSimGray,
                    iconSelected: IconSimBlue,
                    text: 'Sim',
                },
                {
                    iconDefault: IconAccessoriesGray,
                    iconSelected: IconAccessoriesBlue,
                    text: 'Accessories',
                },
            ]
        },
    ]

    // SIDEBAR ITEM LIST (TTTLE ONLY) WHEN THE SIDEBAR IS CLOSED
    const itemTitleComponent = (item) => (
        <Typography
            variant='subtitle1'
            className={classes['title']}    
        >
            {item['title']}
        </Typography>
    )

    // SIDEBAR ITEM LIST (CHILDREN ONLY) WHEN THE SIDEBAR IS CLOSED
    const itemChildrenComponent = (item) => (
        <List disablePadding={true}>
            {
                item['children'].map((childrenItem, childrenIndex) => {
                    const selected =
                        item['title'].toLowerCase() === pathname[0] &&
                        childrenItem['text'].toLowerCase() === pathname[1]

                    const linkPath = `/${item['title'].toLowerCase()}/${childrenItem['text'].toLowerCase()}`

                    return(
                        <Link 
                            key={childrenIndex}
                            to={linkPath} 
                            className={classes['childrenText']}
                        >
                            <ListItem
                                button
                                className={
                                    selected ?
                                    `${classes['iconAndTextContainer']} ${classes['selectedItem']}` :
                                    classes['iconAndTextContainer']
                                }
                            >
                                {/* ICON */}
                                <ListItemIcon>
                                    <img
                                        src={selected ? childrenItem['iconSelected'] : childrenItem['iconDefault']}
                                        alt=''
                                    />
                                </ListItemIcon>
                                
                                {/* TEXT */}
                                <ListItemText>
                                    <Typography
                                        variant='subtitle1'
                                        className={selected ? classes['selectedChildrenText'] : classes['childrenText']}
                                    >
                                        {childrenItem['text']}
                                    </Typography>
                                </ListItemText>

                                {/* ADDITION */}
                                <div className={selected ? classes['addition'] : ''}/>
                            </ListItem>
                        </Link>
                    )
                })
            }
        </List>
    )

    // SIDEBAR ITEM LIST WHEN THE SIDEBAR IS CLOSED
    const itemChildrenClosedComponent = (item) => (
    item['children'].map((childrenItem, childrenIndex) => {
        const selected =
            item['title'].toLowerCase() === pathname[0] &&
            childrenItem['text'].toLowerCase() === pathname[1]

        const linkPath = `/${item['title'].toLowerCase()}/${childrenItem['text'].toLowerCase()}`

        return(
            <Link 
                key={childrenIndex}
                to={linkPath}
            >
                <ListItem
                    button
                    className={
                        selected ?
                        `${classes['iconAndTextClosedContainer']} ${classes['selectedItem']}` :
                        classes['iconAndTextClosedContainer']
                    }
                >
                    {/* ICON */}
                    <ListItemIcon>
                        <img
                            src={selected ? childrenItem['iconSelected'] : childrenItem['iconDefault']}
                            alt=''
                            className={classes['iconClosedSelected']}
                        />
                    </ListItemIcon>

                    {/* ADDITION */}
                    <div className={selected ? classes['addition'] : ''}/>
                </ListItem>
            </Link>
        )
    }))

    return (
        <div className={classes['root']}>
            {
                isSidebarOpen ? 
                itemList.map((item, index) => (
                    <div key={index}>
                        {/* TITLE */}
                        {itemTitleComponent(item)}

                        {/* CHILDREN */}
                        {itemChildrenComponent(item)}
                    </div>
                )) :
                itemList.map((item, index) => (
                    <div key={index}>
                        {itemChildrenClosedComponent(item)}
                    </div>
                ))
            }
        </div>
    )
}

export default SidebarItemList