import React from 'react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
// CONTEXTS
import { SidebarContextProvider } from './contexts/SidebarContext'

// PAGES
import Dashboard from './pages/Dashboard/Dashboard'

function App() {
	return (
		<Router>
			<Redirect from='/' to='/devices/sim'/>
			<Switch>
				<SidebarContextProvider>
					<Dashboard/>
				</SidebarContextProvider>
			</Switch>
		</Router>
	)
}

export default App
